import React from 'react';
import { PageContent } from '../../Components/PageContent/PageContent';


export class Error404Page extends React.Component
{
    public render ( )
    {
        return (
            <PageContent>
                <h1>404 Page not found</h1>
            </PageContent>
        );
    }
}
